<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane disabled label="课堂点评" name="first3"> </el-tab-pane>
        <el-tab-pane label="班级点评" name="first">
          <div class="work-title bg-white">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div
                class="work-title-content flex aligin-items-center flex-wrap-wrap"
              >
                <div  class="cursor" @click="classopenDate">
                  <span>上课日期</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>
                <el-popover width="150" trigger="click">
                  <el-input
                    v-model="shoolInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="shool_list"
                    v-for="(item, index) in shoolList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>校区</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="teachersInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="ArrangeTeachers"
                    v-for="(item, index) in ArrangeTeachersList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>教师</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="assistantInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="assistant"
                    v-for="(item, index) in assistantList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>助教</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>

              <div class="work-title-search">
                <el-input
                  placeholder="请输入作业标题"
                  v-model="stuInput1"
                  class="input-with-select"
                  size="mini"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
                <div 
    @click="clearFilter" class="cursor text-blue margin-top-sm flex justify-content-flexEnd">清空筛选</div>
              </div>
            </div>
            <div
              class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap"
            >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div class="text-gray flex aligin-items-center margin-top-sm">
                  <div>上课日期：</div>
                  <!-- 日期组件 -->
                  <el-date-picker
                    ref="classdatePicker"
                    v-model="classDate1"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                </div>
                <div
                  v-if="shool_list.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                > 
                  <div>校区：</div>
                  <div
                  
                    v-for="(btn, btnIndex) in shool_list"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="delOderStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="ArrangeTeachers.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>教师：</div>
                  <div
                    v-for="(btn, btnIndex) in ArrangeTeachers"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="teachersStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="assistant.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm">
                  <div>助教：</div>
                  <div
                    v-for="(btn, btnIndex) in assistant"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }} 
                      <i
                        class="el-icon-close"
                        @click="assistantStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="myClient-text flex justify-content-between myClient-top-10 margin-left-xs"
          >
            <div>共计16个班级</div>
          </div>
          <div style="margin-top: 10px">
            <as-list :initdataList="datalist">
              <el-table-column
                align="center"
                prop="banji"
                label="班级/一对一"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="教师"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name1"
                label="助教"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text3"
                label="上课记录"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="点评记录"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text4"
                label="点评率"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="阅读率"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text6"
                label="到课人数"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text7"
                label="小红花数"
              ></el-table-column>
              <el-table-column align="center" label="操作">
                <span class="text-blue cursor" @click="editHandle(4)"
                  >查看上课记录</span
                >
              </el-table-column>
            </as-list>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课堂评价" name="third">
          <div class="work-title bg-white">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div class="work-title-content flex aligin-items-center">
                <div class="cursor" @click="openDate(false)">
                  <span>上课日期</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>
                <div class="cursor" @click="openDate(true)">
                  <span>评价日期</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="teachersInput2"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="ArrangeTeachers"
                    v-for="(item, index) in ArrangeTeachersList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>教师</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="starInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="star"
                    v-for="(item, index) in starList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div  class="cursor" slot="reference">
                    <span>星级</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>
              <div class="work-title-search">
                <el-input
                  placeholder="请输入星级/一对一"
                  v-model="stuInput"
                  class="input-with-select"
                  size="mini"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
                <div  
    @click="clearFilter" class="cursor text-blue margin-top-sm flex justify-content-flexEnd">清空筛选</div>
              </div>
            </div>

            <div class="flex aligin-items-center flex-wrap-wrap">
              <div class="text-gray flex aligin-items-center  margin-left-xs">
                <div>上课日期：</div>
                <!-- 日期组件 -->
                <el-date-picker
                  ref="datePicker"
                  v-model="classDate"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini"
                />
              </div>
              <div class="text-gray flex aligin-items-center">
                <div>评价日期：</div>
                <!-- 日期组件 -->
                <el-date-picker
                  ref="evaluation"
                  v-model="evaluationDate"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini"
                />
              </div>
            </div>

            <div
              class="work-title-status flex margin-top-sm aligin-items-center justify-content-between flex-wrap-wrap"
            >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div
                  v-if="ArrangeTeachers.length"
                  class="text-gray flex aligin-items-center margin-left-lg flex-wrap-wrap"
                >
                  <div>教师：</div>
                  <div
                    class="margin-top-sm"
                    v-for="(btn, btnIndex) in ArrangeTeachers"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="teachersStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="star.length"
                  class="text-gray flex aligin-items-center margin-left-lg flex-wrap-wrap"
                >
                  <div>星级：</div>
                  <div
                    class="margin-top-sm"
                    v-for="(btn, btnIndex) in star"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i class="el-icon-close" @click="starStatus(btnIndex)" />
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="myClient-text flex justify-content-between myClient-top-10  margin-left-xs"
          >
            <div>共计16个评价</div>
          </div>
          <div style="margin-top: 10px">
            <as-list :initdataList="datalist">
              <el-table-column
                align="center"
                prop="name"
                label="学员姓名"
              ></el-table-column>
              <!-- <el-table-column label="助教">
					<template class="flex justify-content-around">
						<i class="icon-xiaoxi" title="发送消息"></i> 
						<i class="icon-dianhua" title="打电话"></i> 
						<i class="icon-wenjian2" title="信息"></i> 
						<i class="icon-24px" title="菜单"></i> 
						<i class="icon-folder-add" title="添加"></i> 
					</template>
				</el-table-column> -->
              <el-table-column
                align="center"
                prop="name1"
                label="班级/一对一"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text3"
                label="上课日期"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="教师"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text4"
                label="家长综合评价"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="简评"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text6"
                label="评价日期"
              ></el-table-column>
            </as-list>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <ClassRecord
      :Visible="editVisible && editType === 4"
      @closepop="closeEditPop"
    ></ClassRecord>
  </div>
</template>
<script>

import ClassRecord from "../components/ClassRecord.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    ClassRecord,
  },
  data() {
    return {
      shoolInput:"",
      teachersInput:"",
      teachersInput2:"",
      assistantInput:"",
      starInput:"",
      stuInput:"",
      stuInput1:"",
      assistant: [], //助教
      star: [], //星级
      shool_list: [], //校区
      ArrangeTeachers: [], //教师
      editType: 1,
      classDate1: "", //班级点评上课日期
      evaluationDate: "", //评价日期
      classDate: "", //上课日期
      datalist: [
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
      ],

      options1: [
        {
          value: "选项1",
          label: "一星级",
        },
        {
          value: "选项2",
          label: "二星级",
        },
        {
          value: "选项3",
          label: "三星级",
        },
        {
          value: "选项4",
          label: "四星级",
        },
        {
          value: "选项5",
          label: "五星级",
        },
      ],

      options: [
        {
          value: "校区一",
          label: "校区一",
        },
        {
          value: "校区二",
          label: "校区二",
        },
        {
          value: "校区三",
          label: "校区三",
        },
      ], //校区
      value: "",
      activeName: "first",
      value1: "",
      value2: "",
    };
  },
  methods: {
    clearFilter() {
      this.shool_list = [];
      this.ArrangeTeachers = [];
      this.starS = [];
      this.assistant = [];
      this.classDate= "";
      this.classDate1= "";
      this.evaluationDate= "";
    },
    //教师
    teachersStatus(index) {
      this.ArrangeTeachers.splice(index, 1);
    },
    //校区
    delOderStatus(index) { 
      this.shool_list.splice(index, 1);
    },
    //助教
    assistantStatus(index) { 
      this.assistant.splice(index, 1);
    },
    starStatus(index) { 
      this.star.splice(index, 1);
    },
    openDate(t) {
      if (t) { 
        this.$refs.evaluation.pickerVisible = true;
      } else { 
        this.$refs.datePicker.pickerVisible = true;
      }
    },
    classopenDate(t) {
      this.$refs.classdatePicker.pickerVisible = true;
    },
    handleClick(row) { 
    },
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
  },
};
</script>

<style lang="scss">
.total {
  width: 100%;
}
.shool {
  margin: 0px 10px;
}
// .configure {
//   width: 100%;
//   // background-color: #fff;
//   padding: 30px 10px 10px 10px ;
// }
.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>