<template>
	<el-dialog title="查看上课记录" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="50%">

		<div >
			<span>班级名称：许仙_驿都城初三1v1(三星级)课程（1对1）</span>
			<div class="myClient-text flex justify-content-between myClient-top-10">
				<div>
					共计1条上课记录
				</div>
			</div>
			<el-table :data="datalist_enterprise" height="300px" border class="myClient-top-20">
				<el-table-column property="className" label="上课日期" align="center"></el-table-column>
				<el-table-column property="schoolName" label="上课时间" align="center"></el-table-column>
				<el-table-column property="primary" label="上课教师" align="center"></el-table-column>
				<el-table-column property="text1" label="助教" align="center"></el-table-column>
				<el-table-column property="text2" label="到课人数" align="center"></el-table-column>
				<el-table-column property="text3" label="阅读人数" align="center"></el-table-column>
				<el-table-column property="text4" label="点评状态" align="center"></el-table-column>
				<el-table-column property="primary" label="操作" align="center">
					 <span  class="text-blue" >去点评</span>
					  
				</el-table-column>
			</el-table>
			
		</div>
		<addGroup :Visible="editVisible" @closepop="closeEditPop"></addGroup>
	</el-dialog>
</template>

<script>
	import addGroup from './addGroup.vue'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			}
		},
		components:{
			addGroup
		},
		data() {
			return {
				dialogVisible: false,
				change: 'first',
				text: '',
				ClientName: '',
				checked: '',
				datalist_enterprise: [{
						className: '2021-02-03',
						schoolName: '-',
						primary: '周',
						text1:"程铮",
						text2:"1",
						text3:"0",
						text4:"未点评"
					}
					 
				],

			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},

		}
	}
</script>

<style>
</style>
