<template>
	<el-dialog title="创建分组" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div style="height: 110px;">
			<el-input placeholder="分组名10个字以内" v-model="groupNmae"></el-input>
			<div class="myClient-text myClient-top-10">
				<div>
					您还可以创建<label>99</label>个分组
				</div>
			</div>
			<div class="flex justify-content-flexEnd">
				<el-button type="primary">确定</el-button>
				<el-button class="myClient-left-30">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				dialogVisible: false,
				groupNmae: ''

			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},

		}
	}
</script>

<style>
</style>
